// Fonts & spacing

$base-font-family: "PT Sans", sans-serif;
$base-font-size: 18px;
$small-font-size: $base-font-size * 0.875;
$base-line-height: 1.5;

$header-font-family: "PT Sans", sans-serif;
$header-font-weight: 700;

$accent-font-family: "Quantico", sans-serif;

$spacing-unit: 30px;

// main site colors

$text-color: #111;
$background-color: #fdfdfd;
// $brand-color: #00a5ff;
$brand-color: #0098eb;
$brand-color-dark: darken($brand-color, 15%);
$grey-color: #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark: darken($grey-color, 25%);
$grey-color-extra-light: lighten($grey-color, 45%);

// brand colors

$brand-color-primary-blue: $brand-color;
$brand-color-primary-grey: $grey-color-dark;

$brand-color-secondary-blue: #3fbbff;
$brand-color-secondary-warm: #ff8311;

$brand-color-tertiary-blue: #7fd1ff;
$brand-color-tertiary-warm: #cc4714;
$brand-color-tertiary-cool: #3d4899;

// Width of the content area

$content-width: 900px;

$on-palm: 480px;
$on-tablet: 768px;
$on-laptop: 900px;

// Mixin for media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }

@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}
